<template>
    <ais-instant-search-ssr>
        <ais-configure :hits-per-page.camel="17" />

        <div
            class="-mt-12 mb-7 flex justify-between gap-4 sm:-mt-16 md:justify-center md:gap-16 lg:-mt-20 lg:mb-8 lg:gap-20 xl:-mt-24 xl:gap-28"
        >
            <ais-toggle-refinement
                v-for="filterImage in filterImages"
                :key="filterImage.title"
                :attribute="filterImage.attribute"
                label=""
                :on="filterImage.on"
            >
                <template #default="{ value, refine, createURL }">
                    <a
                        :href="createURL(filterImage)"
                        class="group flex w-24 flex-none flex-col items-center gap-2 text-center text-grind no-underline sm:w-32 md:justify-center lg:w-40 xl:w-48"
                        @click.prevent="refine(value)"
                    >
                        <span
                            class="flex aspect-square items-center rounded-full bg-grind-100"
                        >
                            <StoryblokImage
                                class="relative z-20 aspect-[4/3] w-full bg-transparent object-contain"
                                :src="filterImage.image.src"
                                :alt="filterImage.image.alt"
                                sizes="280px"
                        /></span>
                        <span class="group-hover:underline">{{
                            filterImage.title
                        }}</span>
                    </a>
                </template>
            </ais-toggle-refinement>
        </div>
        <div class="gap-x-8 lg:flex">
            <div class="flex-none">
                <div
                    class="btn-primary flex items-center justify-center gap-x-2 lg:hidden lg:flex-none"
                    @click="mobileMenuOpen = true"
                >
                    Filter <Icon name="bars-filter-solid" class="text-xl" />
                </div>
                <transition name="slide-up">
                    <div
                        v-show="mobileMenuOpen"
                        class="fixed inset-x-0 top-0 z-[999999] h-screen overflow-y-scroll bg-white px-4 py-4 lg:hidden lg:flex-none"
                    >
                        <div class="mb-4 flex justify-between">
                            <h3 class="text-xl">Product Filter</h3>
                            <Icon
                                name="xmark-solid"
                                class="text-xl"
                                @click="mobileMenuOpen = false"
                            />
                        </div>
                        <FiltersLists :mobile-menu-open="mobileMenuOpen" />
                        <div class="mt-4 flex">
                            <div
                                class="btn-primary w-full text-center"
                                @click="mobileMenuOpen = false"
                            >
                                <ais-stats>
                                    <template #default="{ nbHits }">
                                        <div class="text-white">
                                            View {{ nbHits }}
                                            <span v-if="nbHits > 1"
                                                >results</span
                                            ><span v-else>result</span>
                                        </div>
                                    </template>
                                </ais-stats>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
            <aside class="flex-none lg:top-32 lg:w-1/4 lg:self-start">
                <div class="hidden lg:block">
                    <FiltersLists />
                </div>
            </aside>
            <div class="mt-8 flex-1 lg:mt-0">
                <div class="w-full lg:min-h-[44px]">
                    <ais-current-refinements class="mb-4 mt-0 flex gap-2">
                        <template #default="{ items, createURL }">
                            <template
                                v-for="item in items"
                                :key="item.attribute"
                            >
                                <div
                                    v-for="refinement in item.refinements"
                                    :key="
                                        [
                                            refinement.attribute,
                                            refinement.type,
                                            refinement.value,
                                            refinement.operator,
                                        ].join(':')
                                    "
                                    class="rounded-md bg-white shadow-md hover:bg-gray-100"
                                >
                                    <a
                                        :href="createURL(refinement)"
                                        class="flex items-center gap-2 px-5 py-3 text-xs uppercase text-grind no-underline"
                                        @click.prevent="item.refine(refinement)"
                                    >
                                        {{ refinement.label }}
                                        <Icon
                                            name="filter-x"
                                            class="text-xs text-grind"
                                        />
                                    </a>
                                </div>
                            </template>
                        </template>
                    </ais-current-refinements>
                </div>
                <ais-infinite-hits>
                    <template #default="{ items, isLastPage }">
                        <div
                            class="mt-4 grid grid-cols-1 gap-x-4 gap-y-6 md:grid-cols-2 xl:grid-cols-3"
                        >
                            <UploadOrAiCard />
                            <ThemeFilterCard
                                v-for="item in items"
                                :key="item.objectID"
                                :theme="item"
                            />
                        </div>
                        <div v-if="!isLastPage" class="mt-10 text-center">
                            <button class="btn-primary" @click="showMore()">
                                Show More
                            </button>
                        </div>
                    </template>
                </ais-infinite-hits>
                <div class="invisible">
                    <!-- This exists solely for Google to crawl, hence why it's invisible to the user: -->
                    <ais-pagination></ais-pagination>
                </div>

                <div
                    v-if="$slots['product-filter-cta-mobile']"
                    class="mb-12 mt-8 md:mb-20 lg:hidden"
                >
                    <slot name="product-filter-cta-mobile" />
                </div>
            </div>
        </div>
    </ais-instant-search-ssr>
</template>

<script lang="ts" setup>
import {
    AisClearRefinements,
    AisConfigure,
    AisCurrentRefinements,
    AisInfiniteHits,
    AisInstantSearchSsr,
    AisPagination,
    AisRefinementList,
    AisStats,
    AisToggleRefinement,
    createServerRootMixin,
} from 'vue-instantsearch/vue3/es';

import { history as historyRouter } from 'instantsearch.js/es/lib/routers';
import { ref } from 'vue';
import { renderToString } from '@vue/server-renderer';
import ThemeFilterCard from '~/components/page-building/design-lab-pick-your-product/components/ThemeFilterCard.vue';
import FiltersLists from '~/components/page-building/design-lab-pick-your-product/components/FiltersLists.vue';
import { useAlgoliaProductStructuredData } from '~/composables/useStructuredData';
import { useRuntimeConfig } from '#app/nuxt';
import UploadOrAiCard from '~/components/page-building/design-lab-pick-your-product/components/UploadOrAiCard.vue';
import AisFilterButtonImageType from '~/types/AisFilterButtonImageType';
import { titleCase } from '~/utils/helpers';

defineProps<{
    filterImages: AisFilterButtonImageType[];
}>();

const algoliaDesignLabThemeIndex = computed<string>(() => {
    return useRuntimeConfig().public.algoliaDesignLabThemeIndex;
});

const algoliaIndex = computed<string>(() => {
    return algoliaDesignLabThemeIndex.value;
});

const mobileMenuOpen = ref(false);
const indexName = algoliaIndex.value;
const route = useRuntimeConfig().public.baseUrl + useRoute().path;
const includePageInRoute = ref(true);
const initialPage = 0;

const defaultRefinementList: any = {};
const routeQuery = useRoute().query;
for (const property in routeQuery) {
    if (property == 'product_type') {
        defaultRefinementList.product_type = [titleCase(routeQuery[property])];
    }
    if (property == 'theme_group' && routeQuery[property] != 'all') {
        defaultRefinementList.theme_group = [titleCase(routeQuery[property])];
    }
}

const algolia = useAlgoliaRef();
const serverRootMixin = ref(
    createServerRootMixin({
        searchClient: algolia,
        routing: {
            router: historyRouter({
                getLocation() {
                    if (typeof window === 'undefined') {
                        return new URL(route);
                    }
                    return window.location;
                },
                createURL({ routeState }) {
                    const baseUrl = useRuntimeConfig().public.baseUrl;
                    const designLabURL = `${baseUrl}/design-lab`;
                    let refinementSuffix = '';

                    if (routeState[indexName]) {
                        if (routeState[indexName].refinementList?.theme_group) {
                            const themeGroup =
                                routeState[indexName].refinementList
                                    ?.theme_group[0];

                            refinementSuffix += `/${slugify(themeGroup)}`;
                        } else {
                            refinementSuffix += '/all';
                        }

                        if (
                            routeState[indexName].refinementList?.product_type
                        ) {
                            const productType =
                                routeState[indexName].refinementList
                                    ?.product_type[0];

                            refinementSuffix += `/${slugify(productType)}`;
                        }
                    }

                    if (refinementSuffix !== '/all') {
                        return designLabURL + refinementSuffix;
                    }

                    return designLabURL;
                },
            }),
        },
        indexName,
        initialUiState: {
            [algoliaIndex.value]: {
                configure: {
                    hitsPerPage: 17,
                    page: initialPage,
                },
                refinementList: defaultRefinementList,
            },
        },
    }),
);

function showMore() {
    includePageInRoute.value = false;
    instantsearch.renderState[indexName].infiniteHits.showMore();
}

const { instantsearch } = serverRootMixin.value.data();
provide('$_ais_ssrInstantSearchInstance', instantsearch);

const { data: algoliaState } = await useAsyncData('algolia-state', async () => {
    if (process.server) {
        const nuxtApp = useNuxtApp();
        nuxtApp.$algolia.transporter.requester = (
            await import('@algolia/requester-node-http').then(
                (lib) => lib.default || lib,
            )
        ).createNodeHttpRequester();
    }
    return instantsearch.findResultsState({
        // IMPORTANT: a component with access to `this.instantsearch` to be used by the createServerRootMixin code
        component: {
            $options: {
                components: {
                    AisInstantSearchSsr,
                    AisStats,
                    AisConfigure,
                    AisRefinementList,
                    AisClearRefinements,
                    AisCurrentRefinements,
                    AisInfiniteHits,
                },
                data() {
                    return { instantsearch };
                },
                provide: { $_ais_ssrInstantSearchInstance: instantsearch },
                render() {
                    return h(AisInstantSearchSsr, null, () => [
                        // Include any vue-instantsearch components that you use including each refinement attribute
                        h(AisInfiniteHits),
                        h(AisStats),
                        h(AisClearRefinements),
                        h(AisCurrentRefinements),
                        h(AisConfigure),
                        h(AisRefinementList, {
                            attribute: 'product_type',
                            operator: 'or',
                        }),
                        h(AisRefinementList, {
                            attribute: 'theme_group',
                            operator: 'or',
                        }),
                        h(AisRefinementList, {
                            attribute: 'capacity',
                            operator: 'or',
                        }),
                        h(AisRefinementList, {
                            attribute: 'eco_friendly',
                            operator: 'or',
                        }),
                        h(AisRefinementList, {
                            attribute: 'minimum',
                            operator: 'or',
                        }),
                    ]);
                },
            },
        },
        renderToString,
    });
});

for (const hit in algoliaState.value[indexName].results[0].hits) {
    const structuredData = useAlgoliaProductStructuredData(
        algoliaState.value[indexName].results[0].hits[hit],
    );
    useServerHead(() => ({
        script: structuredData,
    }));
}

onBeforeMount(() => {
    instantsearch.hydrate(algoliaState.value);
});
</script>

<style scoped>
.slide-up-enter-active,
.slide-up-leave-active {
    transition: 0.5s ease;
    top: 0;
}

.slide-up-enter-from,
.slide-up-leave-to {
    top: 100%;
}
</style>
