<template>
    <div>
        <div class="h-8 bg-spice"></div>
        <BlockContainer
            background-color="transparent"
            :bottom-angle="pickYourProduct.bottomAngle"
            :angle-background-color="pickYourProduct.nextBackgroundColor"
        >
            <DesignLabThemeFilter
                v-editable="blok"
                :filter-images="pickYourProduct.filterImages"
            />
        </BlockContainer>
    </div>
</template>

<script setup lang="ts">
import DesignLabPickYourProductType from '~/types/DesignLabPickYourProductType';
import DesignLabThemeFilter from '~/components/page-building/design-lab-pick-your-product/DesignLabThemeFilter.vue';
import AisFilterButtonImageType from '~/types/AisFilterButtonImageType';

const props = defineProps({
    blok: {
        type: Object,
        required: true,
    },
    nextBlock: {
        type: Object,
        required: false,
        default: null,
    },
});

const pickYourProduct = computed<DesignLabPickYourProductType>(() => {
    return {
        filterImages: [
            {
                image: {
                    title: props.blok.gusset_image.title,
                    alt: props.blok.gusset_image.alt,
                    src: props.blok.gusset_image.filename,
                },
                title: 'Gusset Bags',
                attribute: 'product_type',
                on: 'Gusset Bag',
            } as AisFilterButtonImageType,
            {
                image: {
                    title: props.blok.flat_bottom_image.title,
                    alt: props.blok.flat_bottom_image.alt,
                    src: props.blok.flat_bottom_image.filename,
                },
                title: 'Flat Bottom Bags',
                attribute: 'product_type',
                on: 'Flat Bottom Bag',
            } as AisFilterButtonImageType,
            {
                image: {
                    title: props.blok.tin_can_image.title,
                    alt: props.blok.tin_can_image.alt,
                    src: props.blok.tin_can_image.filename,
                },
                title: 'Tin Cans',
                attribute: 'product_type',
                on: 'Tin Can',
            } as AisFilterButtonImageType,
        ] as AisFilterButtonImageType[],

        backgroundColor: props.blok.background_color,
        nextBackgroundColor: props.nextBlock
            ? props.nextBlock.background_color
            : null,
        bottomAngle: props.blok.bottom_angle,
    };
});
</script>
