<template>
    <Modal ref="modal" :show-action-buttons="false">
        <template #title>Pick your product</template>
        <div class="grid w-full grid-cols-3 gap-3">
            <NuxtLink
                v-for="product in pickYourProductData"
                :key="product.productId"
                class="group flex flex-col text-center text-grind no-underline"
                :to="
                    '/roastar-design-lab#product=' +
                    product.productId +
                    '&method=' +
                    method
                "
            >
                <span
                    class="mb-2 flex aspect-square rounded-lg border border-grind-200 ring-inset ring-grind group-hover:border-grind group-hover:ring-2"
                >
                    <img :src="product.src" alt="Roastar Design Lab" />
                </span>
                <span>{{ product.name }}</span>
                <span class="text-xs text-grind-800">{{
                    product.subtitle
                }}</span>
            </NuxtLink>
        </div>
    </Modal>
</template>

<script setup lang="ts">
import Modal from '~/components/Modal.vue';
import { ref } from 'vue';
import PickYourProductDataType from '~/types/PickYourProductDataType';

const modal = ref<InstanceType<typeof Modal> | undefined>();
const method = ref<'upload' | 'ai' | undefined>();
const pickYourProductData = ref<PickYourProductDataType[] | undefined>();

const open = (
    openMethod: 'upload' | 'ai',
    productData: PickYourProductDataType[],
) => {
    method.value = openMethod;
    pickYourProductData.value = productData;

    modal.value?.open();
};

defineExpose({ open });
</script>
